import axios from 'axios'
import Bing from './MapProvider/Bing';
import GMaps from './MapProvider/GMaps';
import OSM from './MapProvider/OSM';

export default class LocationService {

    static GOOGLE = 'maps';
    static OSM = 'osm';
    static BING = 'bing';

    constructor(config,company_id) {
        this.country = null;
        this.city = null;
        let mapProvider = config.mapProvider ? config.mapProvider : config

        var country = config.restriction ? config.restriction.country : process.env.VUE_APP_MAP_COUNTRY_RESTRICTION;
        var city = config.restriction ? config.restriction.city : process.env.VUE_APP_MAP_CITY_RESTRICTION;

        if (country && country.length > 0) {
            this.country = country;
        }
        if (city && city.length > 0) {
            this.city = city;
        }
        switch (mapProvider) {

            case LocationService.BING:
                const BING_KEY = process.env.VUE_APP_BING_KEY;
                this.map = new Bing(BING_KEY, this.country, this.city,company_id);
                break;

            case LocationService.OSM:
                this.map = new OSM(this.country);
                break;

            case LocationService.GOOGLE:
                const MAPS_KEY = process.env.VUE_APP_MAPS_KEY;
                this.map = new GMaps(MAPS_KEY, this.country);
                break;

            default:
                //error
                break;
        }
    }

    search(locationName) {
        return new Promise((resolve, reject) => {
            this.map.search(locationName).then((response) => {
                var results = this.map.handle(response.data);
                var cityRestricted = '';

                if (typeof this.map.restrictedCity !== 'undefined' && this.map.restrictedCity !== '' && this.map.restrictedCity !== null) {
                    cityRestricted = this.map.restrictedCity.toLowerCase();
                }
                var city = '';
                if (response.data.resourceSets[0].resources.length > 0 && typeof response.data.resourceSets[0].resources[0].address.locality !== 'undefined') {
                    city = response.data.resourceSets[0].resources[0].address.locality.toLowerCase();
                }

                if (city === cityRestricted || cityRestricted === '') {

                    if (results) {
                        resolve(results);
                    }
                    reject(this.map.errors);
                } else {
                    this.map.searchCity(locationName).then((responseCity) => {
                        for (var i = 0; i < responseCity.data.response.resourceSets[0].resources.length; i++) {
                            response.data.resourceSets[0].resources.push(responseCity.data.response.resourceSets[0].resources[i]);
                        }
                        results = this.map.handle(response.data);
                        if (results) {

                            if (results.length > 0) {
                                results.sort((a, b) => {
                                    var aIsLocatedInCity = false;
                                    var bIsLocatedInCity = false;

                                    if (a.address.toLowerCase().includes(cityRestricted) || a.address.toLowerCase().includes(cityRestricted))
                                        aIsLocatedInCity = true;

                                    if (b.address.toLowerCase().includes(cityRestricted) || b.address.toLowerCase().includes(cityRestricted))
                                        bIsLocatedInCity = true;

                                    if (aIsLocatedInCity && bIsLocatedInCity)
                                        return 0;

                                    if (aIsLocatedInCity)
                                        return -1;

                                    if (bIsLocatedInCity)
                                        return 1;
                                })
                            }
                            resolve(results)
                        }
                        reject(this.map.errors)
                    })
                }
            })
        }).catch((error) => {
            console.log('error in Location Service')
            console.log(error)
        })
    }

    reverseSearch(coordinates) {
        return new Promise((resolve, reject) => {
            this.map.reverseSearch(coordinates).then((response) => {
                var formattedAddress = this.map.reverseHandle(response.data);

                if (formattedAddress && formattedAddress.length > 0) {
                    resolve(formattedAddress)
                }

                reject(this.map.errors);
            })
        })
    }

}
