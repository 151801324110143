export default () => ({
    usersConfig: {
        include: 'company',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 15
    },
    users: null,
    usersFilters: null,
    newUser: {
        name: null,
        job_title: null,
        email: null,
        locale: null
    },
    newWebhook: {
        name: null,
        url: null,
        signature_secret: null
    },
    company: null,
    companySettings: null,
    companyHeaderMail: null
})
