import axios from 'axios'

export default class GMaps {

    search(){

    }
    
    handle(){

    }

    reverseSearch(){

    }
    
    reverseHandle(){

    }

}